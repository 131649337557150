import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ISelecter from "../../../Models/DTOs/ISelecter";
import AuthContext from "../../../Store/auth-context";
import PinkButton from "../Buttons/PinkButton";
import Heading from "../Text/Heading";
import CentralisedPrimaryText from "../Text/CentralisedPrimaryText";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import {
    GetCancellationReasons,
    GetDevices,
    GetBrowsers,
    GetTechnicalIssues,
    NewCancelSubscription,
    // NewPauseSubscription,
} from "../../../Api/Cancellation";
import "video.js/dist/video-js.css"; // Import default video.js styles
import videojs from "video.js"; // Make sure to install video.js using `npm install video.js"

// Styled components
const PurpleText = styled(CentralisedPrimaryText)`
    color: purple;
`;

const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
`;

const ButtonContainer = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
`;

const VideoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`;

const FeedbackTextArea = styled.textarea`
    width: 90%;
    height: 100px;
    margin-top: 20px;
    padding: 10px;
    font-size: 14pt;
    border-radius: 10px;
    border: 1px solid #ccc;
    resize: vertical;
`;

const OtherTextArea = styled.textarea`
    width: 90%;
    height: 50px;
    margin-top: 10px;
    padding: 10px;
    font-size: 12pt;
    border-radius: 8px;
    border: 1px solid #ccc;
    resize: vertical;
`;

const CheckboxContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    color: white;
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
`;

const CheckboxInput = styled.input`
    width: 20px;
    height: 20px;
`;

const SelectContainer = styled.div`
    margin-top: 20px;
    width: 90%;
`;

const SelectList = styled.select`
    width: 100%;
    padding: 10px;
    font-size: 14pt;
    border-radius: 8px;
    border: 1px solid #ccc;
`;

const SuccessMessageStep8 = styled.div`
    margin-top: 20px;
    color: green;
    font-weight: bold;
    text-align: center;
`;

// const PauseMessage = styled.div`
//     margin-top: 20px;
//     color: blue;
//     font-weight: bold;
//     text-align: center;
// `;

const GreenText = styled.div`
    color: green;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
`;

const ErrorMessage = styled.div`
    color: red;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
`;

interface CancelPauseProps {
    onSwitchOff: (setStep: (step: number) => void, setReason: (reason: string) => void) => void; // Include setReason
}

function CancelOrPause({ onSwitchOff }: CancelPauseProps) {
    const authCtx = useContext(AuthContext);
    const controller = new AbortController();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cancelErrorMessage, setCancelErrorMessage] = useState<string | null>(
        null
    );
    const [cancellationMessage, setCancellationMessage] = useState<string | null>(null);

    // State for step navigation
    const [step, setStep] = useState<number>(2); // Start from Step 2

    // State for handling reason selection
    const reasonRef = useRef<HTMLInputElement | null>(null);
    const [options, setOptions] = useState<ISelecter[]>([]);
    const [selectedReason, setSelectedReason] = useState<string | null>(null);
    const [reasonIsValid, setReasonIsValid] = useState<boolean>(true);
    const [reasonChanged, setReasonChanged] = useState<boolean>(false);
    const [reasonError, setReasonError] = useState<string>("");
    // Define videoRef with the correct type
    const videoRef = useRef<HTMLVideoElement | null>(null);

    // State for handling technical issues, devices, browsers
    const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
    const [otherIssue, setOtherIssue] = useState<string>("");
    const [showOtherInput, setShowOtherInput] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<string>("");
    const [deviceOptions, setDeviceOptions] = useState<
        { value: string; label: string }[]
    >([]);
    const [selectedBrowser, setSelectedBrowser] = useState<string>("");
    const [browserOptions, setBrowserOptions] = useState<
        { value: string; label: string }[]
    >([]);
    const [technicalIssuesOptions, setTechnicalIssuesOptions] = useState<
        { value: string; label: string }[]
    >([]);

    // Feedback for content dissatisfaction or other reasons
    const [feedback, setFeedback] = useState<string>("");

    // Validation error messages for technical issues and feedback
    const [validationMessage, setValidationMessage] = useState<string | null>(
        null
    );

    const [dummyUpdate, setDummyUpdate] = useState(false); // Add a dummy state

    const PopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 300px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: visible;
    border: 1px solid purple; /* Purple border */
    background-color: white; /* Optional for consistent appearance */
    `;

    // State for pause
    // const [pauseMonths, setPauseMonths] = useState<number | null>(null);
    // const [pauseSuccess, setPauseSuccess] = useState<string | null>(null);
    // const [isPaused, setIsPaused] = useState<boolean>(false);
    // const [newSubDate, setNewSubDate] = useState<string | null>(null);
    // const [pauseOptions, setPauseOptions] = useState<
    //     { label: string; value: number }[]
    // >([]);

    // Attach the custom event handler to manage steps
    useEffect(() => {
        const cleanup = onSwitchOff((step) => setStep(step), (reason) => setSelectedReason(reason));
    
        return cleanup; // Cleanup on unmount
    }, [onSwitchOff]);

    // Fetch cancellation reasons
    useEffect(() => {
        async function fetchCancellationReasons() {
            try {
                const response = await GetCancellationReasons();
                const formattedOptions = response.map((item) => ({
                    value: item.plKey,
                    label: item.plTitle,
                }));
                setOptions(formattedOptions);
            } catch (error) {
                console.error("Error fetching cancellation reasons:", error);
            }
        }

        fetchCancellationReasons();
    }, []);

    // Fetch devices and browsers
    useEffect(() => {
        async function fetchDevicesAndBrowsers() {
            try {
                const devices = await GetDevices();
                const browsers = await GetBrowsers();

                const formattedDevices = devices.map((device) => ({
                    value: device.plKey,
                    label: device.plTitle,
                }));

                const formattedBrowsers = browsers.map((browser) => ({
                    value: browser.plKey,
                    label: browser.plTitle,
                }));

                setDeviceOptions(formattedDevices);
                setBrowserOptions(formattedBrowsers);
            } catch (error) {
                console.error("Error fetching devices or browsers:", error);
            }
        }

        fetchDevicesAndBrowsers();
    }, []);

    // Fetch technical issues
    useEffect(() => {
        async function fetchTechnicalIssues() {
            try {
                const issues = await GetTechnicalIssues();
                const formattedIssues = issues.map((issue) => ({
                    value: issue.plKey,
                    label: issue.plTitle,
                }));
    
                console.log("Technical Issues Options:", formattedIssues); // Debugging log
    
                setTechnicalIssuesOptions(formattedIssues);
            } catch (error) {
                console.error("Error fetching technical issues:", error);
            }
        }
    
        fetchTechnicalIssues();
    }, []);

    // Fetch pause options (for Step 7)
    // useEffect(() => {
    //     setPauseOptions([
    //         { label: "1 Month", value: 1 },
    //         { label: "2 Months", value: 2 },
    //         { label: "3 Months", value: 3 },
    //     ]);
    // }, []);

    // Handle reason selection
    const ReasonOnChange = (selectedOption: ISelecter | null) => {
        if (selectedOption) {
            setSelectedReason(selectedOption.value);
            setReasonChanged(true);
            setReasonIsValid(true);
            setReasonError("");

            // Navigate to appropriate step based on the selected reason
            switch (selectedOption.value) {
                case "9":
                    setStep(6); // Navigate to step 6 for "Too Expensive" for more feedback
                    break;
                case "10":
                    setStep(4); // Navigate to step 4 for "Unsatisfied with Content"
                    break;
                case "11":
                    setStep(5); // Navigate to step 5 for "Technical Issues"
                    break;
                case "12":
                    setStep(6); // Navigate to step 6 for "Other"
                    break;
                default:
                    break;
            }
        } else {
            setReasonIsValid(false);
            setReasonError("Please select a reason for cancelling.");
        }
    };

    // Validate technical issues selection
    const validateTechnicalIssues = () => {
        if (selectedIssues.length === 0) {
            setValidationMessage("Please select at least one issue.");
            return false;
        }
        if (!selectedDevice || !selectedBrowser) {
            setValidationMessage("Please select a device and a browser.");
            return false;
        }
        setValidationMessage(null);
        return true;
    };

    // Validate feedback
    const validateFeedback = () => {
        if (feedback.trim().length === 0) {
            setValidationMessage("Please provide feedback.");
            return false;
        }
        setValidationMessage(null);
        return true;
    };

    // Handle membership cancellation
    const handleCancelMembership = async (
        reasonId: number,
        technicalIssues: string[],
        deviceId: number,
        browserId: number,
        contentComment: string
    ) => {
        try {
            setIsLoading(true);
            setCancelErrorMessage(null); // Clear previous error message
    
            const technicalIssuesString = technicalIssues.join(',');
    
            const data: any = {
                AspNetUserId: authCtx.UserDetails.AspNetUserId,
                SubCancelReason: reasonId,
                TechnicalIssues: technicalIssuesString,
                DeviceUsedToView: deviceId,
                BrowserUsedToView: browserId,
                Content: contentComment || "No comment",
            };
    
            console.log("Payload being sent to API:", data);
    
            const resultMessage = await NewCancelSubscription(
                data,
                authCtx.UserDetails.Access_Token,
                controller
            );
    
            if (resultMessage) {
                setCancellationMessage(resultMessage); // Set the returned message
                setStep(8); // Navigate to Step 8
            } else {
                setCancelErrorMessage(
                    "There was an Error cancelling Your Membership - Please contact support@ickonic.com"
                );
            }
        } catch (error) {
            console.error("Error cancelling membership:", error);
            setCancelErrorMessage(
                "There was an Error cancelling Your Membership - Please contact support@ickonic.com"
            );
        } finally {
            setIsLoading(false);
        }
    };
 
    // Handle pause membership with a specified duration (in months)
    // const handlePauseMembership = async (pauseMonths: number) => {
    //     try {
    //         setIsLoading(true);

    //         // Prepare data to send to the API
    //         const data = {
    //             AspNetUserId: authCtx.UserDetails.AspNetUserId,
    //             PauseDurationInMonths: pauseMonths, // Pass the fixed pause duration
    //         };

    //         // Call the API to pause the subscription
    //         const result = await NewPauseSubscription(
    //             data,
    //             authCtx.UserDetails.Access_Token,
    //             controller
    //         );

    //         if (result) {
    //             // If API call is successful, calculate and display the pause end date
    //             const pauseEndDate = new Date();
    //             pauseEndDate.setMonth(pauseEndDate.getMonth() + pauseMonths);
    //             const formattedDate = pauseEndDate.toLocaleString("en-US", {
    //                 month: "long",
    //                 year: "numeric",
    //             });

    //             // Display success message
    //             setPauseSuccess(
    //                 `Your membership is paused for ${pauseMonths} month(s). It will resume in ${formattedDate}.`
    //             );
    //             setNewSubDate(formattedDate);
    //             setIsPaused(true);
    //         } else {
    //             setPauseSuccess("Failed to pause the membership.");
    //         }

    //         setIsLoading(false);
    //     } catch (error) {
    //         console.error("Error pausing membership:", error);
    //         setPauseSuccess("An error occurred while trying to pause your membership.");
    //         setIsLoading(false);
    //     }
    // };

    // Handle blur event for validation
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!selectedReason) {
            setReasonIsValid(false);
            setReasonError("Please select a reason for cancelling.");
        } else {
            setReasonIsValid(true);
            setReasonError("");
        }
    };

   // Initialize Video.js player in Step 4
    useEffect(() => {
        if (step === 4 && videoRef.current) {
            const player = videojs(videoRef.current, {
                controls: true,
                autoplay: true,
                preload: "auto",
                sources: [
                    {
                        src: "https://storage.ickonic.com/IckonicVideo/asset-33925b8a-a203-4a8b-9ec9-9736ce8df384/Ickonic_Cancellation_Message.m3u8",
                        type: "application/x-mpegURL", // HLS format
                    },
                ],
            });

            return () => {
                player.dispose();
            };
        }
    }, [step]);

    return (
        <Container onSubmit={(e) => e.preventDefault()}>
            {/* Step 1 - Pause Membership */}
            {step === 1 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                    }}
                >
                    <Heading type={HeadingType.H2}>
                        Would you like to cancel your Membership?
                    </Heading>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        {/* <CentralisedPrimaryText>
                            You can pause your subscription for up to 3 months
                            without cancelling.
                        </CentralisedPrimaryText> */}
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center", // This centers the Cancel button horizontally
                            alignItems: "center",
                            width: "100%",
                            marginTop: "25px", // Add margin at the top for spacing
                        }}
                    >
                        <PinkButton onClick={() => setStep(2)}>
                            Cancel
                        </PinkButton>
                    </div>
                </div>
            )}

            {/* Step 2 - Reason for cancellation */}
            {step === 2 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "600px",
                        height: "300px",
                        padding: "15px",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Heading type={HeadingType.H2}>
                        We are sorry to see you go
                    </Heading>
                    <CentralisedPrimaryText>
                        Please tell us your reason for leaving so we can learn and improve.
                    </CentralisedPrimaryText>

                    <div style={{ marginTop: "20px", width: "90%" }}>
                        <PurpleText>Reason for leaving</PurpleText>
                        <SelectList
                            value={selectedReason || ""}
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                setSelectedReason(selectedValue); // Update state
                                if (selectedValue !== "0") {
                                    const optionObject = options.find(
                                        (option) => option.value === selectedValue
                                    );
                                    if (optionObject) {
                                        ReasonOnChange(optionObject); // Pass the correct object type
                                    }
                                }
                            }}
                        >
                            <option value="0">Select a reason</option>
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </SelectList>
                        {reasonError && <ErrorMessage>{reasonError}</ErrorMessage>}
                    </div>
                </div>
            )}

            {/* Step 3 - Special offer */}
            {step === 3 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Heading type={HeadingType.H2}>SPECIAL OFFER</Heading>
                    <CentralisedPrimaryText>
                        We would like to offer you a free month instead of cancelling.
                    </CentralisedPrimaryText>
                    <CentralisedPrimaryText>
                        Take a little time to think about staying with the Family.
                    </CentralisedPrimaryText>
                    <CentralisedPrimaryText>
                        You can still cancel at any time.
                    </CentralisedPrimaryText>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            gap: "20px",
                        }}
                    >
                        <PinkButton
                            type="button"
                            // onClick={() => handlePauseMembership(1)} // Pause for 1 month
                        >
                            Accept Offer
                        </PinkButton>
                        <PinkButton
                            type="button"
                            onClick={async () => {
                                setIsLoading(true);
                                await handleCancelMembership(
                                    parseInt(selectedReason || "0"),
                                    [''],
                                    0,
                                    0,
                                    feedback || ""
                                );
                                setIsLoading(false);
                            }}
                        >
                            {isLoading ? "Cancelling..." : "Cancel Subscription"}
                        </PinkButton>
                    </div>

                    {/* Display error message if cancellation fails */}
                    {cancelErrorMessage && (
                        <ErrorMessage>{cancelErrorMessage}</ErrorMessage>
                    )}
                </div>
            )}

            {step === 4 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Heading type={HeadingType.H2}>
                        Coming soon on Ickonic
                    </Heading>

                    <div style={{ marginBottom: "20px", textAlign: "center" }}>
                        <CentralisedPrimaryText>
                            Don’t miss out on what’s coming next! Watch our sneak peek of exciting new content we’ve lined up just for you.
                        </CentralisedPrimaryText>
                    </div>

                    <VideoContainer>
                        <video
                            ref={videoRef}
                            className="video-js vjs-default-skin"
                            controls
                            preload="auto"
                            width="640"
                            height="264"
                            data-setup="{}"
                        />
                    </VideoContainer>

                    <div style={{ marginTop: "30px", display: "flex", gap: "15px" }}>
                        <PinkButton
                            type="button"
                            onClick={() => setStep(9)} // Navigate to Step 8
                            title="Stay with Ickonic"
                        >
                            Stay on Board
                        </PinkButton>

                        <PinkButton
                            type="button"
                            onClick={async () => {
                                // Pause the video
                                if (videoRef.current) {
                                    videoRef.current.pause();
                                }

                                setIsLoading(true); // Start the loading state
                                await handleCancelMembership(
                                    parseInt(selectedReason || "0"),
                                    [''],
                                    0,
                                    0,
                                    feedback || ""
                                );
                            }}
                            title="Not interested, cancel my Membership"
                        >
                            {isLoading ? "Switching Off..." : "Switch off Auto Renewal"}
                        </PinkButton>
                    </div>
                </div>
            )}

            {/* Step 9 - Staying with Ickonic */}
            {step === 9 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Heading type={HeadingType.H2}>
                        Thank you for staying with us!
                    </Heading>
                    <CentralisedPrimaryText>
                        We're thrilled that you have decided to stay as part of the Ickonic family. <br />Stay tuned for more exciting content coming your way!
                    </CentralisedPrimaryText>
                   {/*  <ButtonContainer>
                        <PinkButton
                            type="button"
                            onClick={() => setStep(1)} // Navigate back to the first step or close
                        >
                            Back to Home
                        </PinkButton>
                    </ButtonContainer> */}
                </div>
            )}

            {/* Step 5 - Technical Issues */}
            {step === 5 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Heading type={HeadingType.H2}>
                        We are sorry for any technical issues
                    </Heading>
                    <CentralisedPrimaryText>
                        Please describe the issues you encountered.
                    </CentralisedPrimaryText>

                    {validationMessage && (
                        <div style={{ color: "red" }}>{validationMessage}</div>
                    )}
                      <CheckboxContainer>
                        {technicalIssuesOptions.map((issue) => (
                            <CheckboxLabel key={issue.value}>
                                <CheckboxInput
                                    type="checkbox"
                                    value={issue.value.toString()} // Ensure value is a string
                                    onChange={(e) => {
                                        const value = e.target.value; // Value will always be a string
                                        console.log("Checkbox clicked:", value); // Debugging log

                                        const updatedIssues = selectedIssues.includes(value)
                                            ? selectedIssues.filter((i) => i !== value) // Remove if already selected
                                            : [...selectedIssues, value]; // Add if not selected

                                        console.log("Updated Issues:", updatedIssues); // Debugging log

                                        setSelectedIssues(updatedIssues);

                                        // Check for "Other" using its string representation
                                        if (value === "17") {
                                            console.log("Is 'Other' included in updatedIssues?", updatedIssues.includes("17"));
                                            setShowOtherInput(updatedIssues.includes("17"));
                                        }
                                    }}
                                    checked={selectedIssues.includes(issue.value.toString())} // Ensure comparison is consistent
                                />
                                {issue.label}
                            </CheckboxLabel>
                        ))}
                    </CheckboxContainer>
                    {showOtherInput && (
                        <OtherTextArea
                            placeholder="Please describe the issue"
                            value={otherIssue}
                            onChange={(e) => {
                                const text = e.target.value;
                                setOtherIssue(text); // Update the "Other" issue text
                                setFeedback(text); // Save the text into the feedback variable
                            }}
                        />
                    )}

                    <SelectContainer>
                        <CentralisedPrimaryText>
                            Which device did you use most to watch content?
                        </CentralisedPrimaryText>
                        <SelectList
                            value={selectedDevice}
                            onChange={(e) => setSelectedDevice(e.target.value)}
                        >
                            <option value="">Select a device</option>
                            {deviceOptions.map((device) => (
                                <option key={device.value} value={device.value}>
                                    {device.label}
                                </option>
                            ))}
                        </SelectList>
                    </SelectContainer>

                    <SelectContainer style={{ marginBottom: "30px" }}>
                        <CentralisedPrimaryText>
                            Which Browser did you use to watch content?
                        </CentralisedPrimaryText>
                        <SelectList
                            value={selectedBrowser}
                            onChange={(e) => setSelectedBrowser(e.target.value)}
                        >
                            <option value="">Select a browser</option>
                            {browserOptions.map((browser) => (
                                <option key={browser.value} value={browser.value}>
                                    {browser.label}
                                </option>
                            ))}
                        </SelectList>
                    </SelectContainer>

                    <ButtonContainer>
                        <PinkButton
                            type="button"
                            onClick={async () => {
                                if (validateTechnicalIssues()) {
                                    setIsLoading(true);
                                    await handleCancelMembership(
                                        parseInt(selectedReason || "0"),
                                        selectedIssues,
                                        parseInt(selectedDevice || "0"),
                                        parseInt(selectedBrowser || "0"),
                                        feedback || ""
                                    );
                                    setIsLoading(false);
                                }
                            }}
                        >
                            {isLoading ? "Switching Off..." : "Switch Off Auto Renewal"}
                        </PinkButton>

                        {cancelErrorMessage && (
                            <ErrorMessage>{cancelErrorMessage}</ErrorMessage>
                        )}
                    </ButtonContainer>
                </div>
            )}


            {/* Step 6 - Other */}
            {step === 6 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Heading type={HeadingType.H2}>Please tell us more</Heading>
                    <CentralisedPrimaryText>
                        How could we have improved your Ickonic experience?
                        <br />
                        All feedback would be greatly appreciated.
                    </CentralisedPrimaryText>

                    {validationMessage && (
                        <div style={{ color: "red" }}>{validationMessage}</div>
                    )}

                    <FeedbackTextArea
                        placeholder="Enter your feedback here"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />

                    <div style={{ marginTop: "20px" }}>
                        <PinkButton
                            type="button"
                            onClick={async () => {
                                if (validateFeedback()) {
                                    setIsLoading(true);
                                    await handleCancelMembership(
                                        parseInt(selectedReason || "0"),
                                        [''],
                                        0,
                                        0,
                                        feedback || ""
                                    );
                                    setIsLoading(false);
                                }
                            }}
                        >
                            {isLoading
                                ? "Switching Off..."
                                : "Switch Off Auto Renewal"}
                        </PinkButton>
                    </div>

                    {/* Display error message if cancellation fails */}
                    {cancelErrorMessage && (
                        <ErrorMessage>{cancelErrorMessage}</ErrorMessage>
                    )}
                </div>
            )}

            {/* Step 7 - Pause Membership */}
            {/* {step === 7 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Heading type={HeadingType.H2}>
                        Pause Your Membership
                    </Heading>
                    <CentralisedPrimaryText>
                        Choose how long you'd like to pause your membership.
                    </CentralisedPrimaryText>

                    <SelectList
                        value={pauseMonths?.toString() || ""}
                        onChange={(e) =>
                            setPauseMonths(parseInt(e.target.value))
                        }
                        disabled={isPaused} // Disable when paused
                    >
                        <option value="">Select duration</option>
                        {pauseOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </SelectList>

                    <ButtonContainer>
                        <PinkButton
                            type="button"
                            onClick={handlePauseMembership}
                            disabled={isPaused || isLoading} // Disable button if paused or loading
                        >
                            {isPaused
                                ? "Paused"
                                : isLoading
                                ? "Pausing..."
                                : "Pause Now"}
                        </PinkButton>
                    </ButtonContainer>

                    {pauseSuccess && (
                        <PauseMessage>
                            <GreenText>{pauseSuccess}</GreenText>
                            {newSubDate && (
                                <GreenText>
                                    Your membership will resume in {newSubDate}.
                                </GreenText>
                            )}
                        </PauseMessage>
                    )}
                </div>
            )} */}

            {step === 8 && (
                <SuccessMessageStep8>
                    <Heading type={HeadingType.H2}>Subscription Status</Heading>
                    {cancellationMessage ? (
                        <div
                            dangerouslySetInnerHTML={{ __html: cancellationMessage }}
                        />
                    ) : (
                        <CentralisedPrimaryText>
                            Your subscription has been successfully cancelled.
                        </CentralisedPrimaryText>
                    )}
                </SuccessMessageStep8>
            )}
        </Container>
    );
}

export default CancelOrPause;