import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PinkButton from "../Buttons/PinkButton";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const EmailListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
`;

const TransparentPinkButton = styled(PinkButton)`
    box-shadow: none !important;
    border: 2px solid #4b0082 !important;
    background: transparent !important;
    color: white !important;

    border-radius: 999px;
    padding: 10px 25px;
    font-size: 16px;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: #4b0082 !important;
        color: white !important;
        border-color: #4b0082 !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: 0 0 0 4px rgba(75, 0, 130, 0.5);
    }
`;

const EmailRow = styled.div`
    display: flex;
    align-items: baseline;
    gap: 10px;

    input {
        flex: 1;
        max-width: 400px;
        padding: 8px 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        line-height: 1.5;
        box-sizing: border-box;

        &.invalid {
            border-color: red;
        }
    }
`;

const ErrorText = styled.p`
    color: red;
    font-size: 0.9rem;
    margin: -10px 0 10px 0;
`;

interface EmailInputListProps {
    maxEmails: number;
    onEmailsChange: React.Dispatch<React.SetStateAction<string[]>>;
    onValidEmailChange: React.Dispatch<React.SetStateAction<boolean>>;
    disabled?: boolean;
    remainingEmails: number;
    resetEmails?: boolean;
}

const EmailInputList: React.FC<EmailInputListProps> = ({
    maxEmails = 6,
    onEmailsChange,
    onValidEmailChange,
    disabled = false,
    remainingEmails,
    resetEmails = false,
}) => {
    const [emails, setEmails] = useState<string[]>([""]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [debouncedEmails, setDebouncedEmails] = useState<string[]>(emails);

    // Reset emails to a single input box when `resetEmails` changes
    useEffect(() => {
        if (resetEmails) {
            setEmails([""]);
            onEmailsChange([""]);
        }
    }, [resetEmails, onEmailsChange]);

    // Debounce email changes
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedEmails(emails);
        }, 2000); // 2-second debounce

        return () => clearTimeout(timer);
    }, [emails]);

    // Validate emails only after debounce
    useEffect(() => {
        const invalidEmails = debouncedEmails.filter((email) => email && !emailRegex.test(email));
        const hasValidEmail = debouncedEmails.some((email) => emailRegex.test(email));
        setErrorMessage(invalidEmails.length > 0 ? "Please enter valid email addresses." : "");
        onValidEmailChange(hasValidEmail && invalidEmails.length === 0);
    }, [debouncedEmails, onValidEmailChange]);

    const handleInputChange = (index: number, value: string) => {
        const updatedEmails = [...emails];
        updatedEmails[index] = value;
        setEmails(updatedEmails);
        onEmailsChange(updatedEmails);
    };

    const addEmailInput = () => {
        if (emails.length < remainingEmails) {
            setEmails([...emails, ""]);
        } else {
            alert(`You can only add up to ${remainingEmails} emails.`);
        }
    };

    const removeEmailInput = (index: number) => {
        const updatedEmails = emails.filter((_, i) => i !== index);
        setEmails(updatedEmails);
        onEmailsChange(updatedEmails);
    };

    return (
        <>
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            <EmailListContainer>
                {emails.map((email, index) => (
                    <EmailRow key={index}>
                        <input
                            type="email"
                            placeholder={`Enter email ${index + 1}`}
                            value={email}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            className={email && !emailRegex.test(email) ? "invalid" : ""}
                            disabled={disabled}
                        />
                        {emails.length > 1 && (
                            <TransparentPinkButton
                                type="button"
                                onClick={() => removeEmailInput(index)}
                                disabled={disabled}
                            >
                                Remove
                            </TransparentPinkButton>
                        )}
                        {index === emails.length - 1 && emails.length < remainingEmails && (
                            <TransparentPinkButton
                                type="button"
                                onClick={addEmailInput}
                                disabled={disabled}
                            >
                                Add Another
                            </TransparentPinkButton>
                        )}
                    </EmailRow>
                ))}
            </EmailListContainer>
        </>
    );
};

export default EmailInputList;