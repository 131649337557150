import { Suspense, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Await, defer, useLoaderData } from "react-router-dom";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import {
    GetCategories,
    GetCustomCarousels,
    GetFeaturedContent,
    GetLatest,
    GetNewAndCurrentAffairs,
    GetTopRatedCarousel,
    GetEpisodesByYear,
} from "../../../Api/Carousel";
import Carousel from "../../../Components/Carousels/Carousel";
import CarouselItem from "../../../Components/Carousels/CarouselItem";
import CarouselItemPortrait from "../../../Components/Carousels/CarouselItemPortrait";
import FeaturedCarousel from "../../../Components/Carousels/FeaturedCarousel";
import DownloadAppAd from "../../../Components/UI/Ads/DownloadAppAd";
import { HeadingType } from "../../../Models/Enums/HeadingType";
import Heading from "../../../Components/UI/Text/Heading";
import { AuthValidSubOnly } from "../../../Helpers/RouteAuth";
import useWindowDimensions from "../../../Helpers/UseWindowDimensions";
import { notEmpty, SetTitle } from "../../../Helpers/Utility";
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";
import ICarouselDTO from "../../../Models/DTOs/ICarouselDTO";
import { ContentType } from "../../../Models/Enums/ContentType";
import AuthContext, { UserSnapshot } from "../../../Store/auth-context";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { AxiosError } from "axios";
import FeaturedSliderLoader from "../../../Components/UI/PageLoaders/FeaturedSliderLoader";
import ListContainer from "../../../Components/UI/List/ListContainer";
import VideoSingleLoader from "../../../Components/UI/PageLoaders/VideoSingleLoader";
import HeadingLoader from "../../../Components/UI/PageLoaders/HeadingLoader";
import UserOnboarding from "../../../Components/UI/UserOnboarding";
import NewAndroidAppPopUp from "../../../Components/UI/Watch/NewAndroidAppPopUp";
import PopUp from "../../../Components/UI/Modals/PopUp";
import lscache from 'lscache';
import {IconAlertError} from "../../../Assets/Icons/Icons";
import {Colours} from "../../../Constants/Colours";
import ButtonPillUppercase from "../../../Components/UI/Buttons/ButtonPillUppercase";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    overflow: visible !important;

    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
        padding: 1.5rem;
    }
`;

const BrowseContent = styled.div``;

const CarouselContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: calc(32rem/16);
`;

const CarouselObject = styled.div`
    display: flex;
    flex-direction: column;
    h3 {
        margin-top: 0;
        margin-bottom: 1rem;
    }
`;

const MaintenancePopUp = styled.div`
    padding: 2rem;
    background: #232323;
    color: white;
    border-radius: calc(20rem/16);
    max-width: calc(600rem/16);
    svg {
        width: calc(70rem/16);
        height: calc(70rem/16);
        display: block;
        margin: 0 auto;
        * {
            fill: ${Colours.IckonicPinkHighlight};
        }
    }

    h1 {
        line-height: 1.3;
        font-family: "Manrope", Helvetica sans-serif;
    }

    p {
        span {
            background: ${Colours.IckonicPinkHighlight};
            padding: calc(2rem/16) calc(7rem/16);
            border-radius: calc(5rem/16);
            color: black;
            font-weight: bold;
        }
    }
`;

const BrowseCarousel = styled.div``;

const PortraitImageContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: calc(16rem/16);
`;

const BottomImageContainer = styled.div`
    margin: calc(30rem/16) 0 0 0;
`;

function BrowseScreen() {
    const [isNewAndroidAppVisible, setIsNewAndroidAppVisible] = useState(false);
    const POPUP_SHOWN_KEY = "newAndroidAppPopupShown"; // Key for localStorage

    // Maintenance popup state
    const [showMaintenancePopup, setShowMaintenancePopup] = useState(() => {
        // Check ls-cache for a flag that says the maintenance popup was closed
        return lscache.get('maintenancePopupClosed') ? false : true;
    });

    // Function to open the new android popup
    const handleOpenNewAndroidApp = () => {
        setIsNewAndroidAppVisible(true);
    };

    // Function to close the new android popup
    const handleCloseNewAndroidApp = () => {
        setIsNewAndroidAppVisible(false);
        localStorage.setItem(POPUP_SHOWN_KEY, "true"); // Mark popup as shown
    };

    // Check if the new android app popup has already been shown to the user
    useEffect(() => {
        const hasPopupBeenShown = localStorage.getItem(POPUP_SHOWN_KEY);
        if (!hasPopupBeenShown) {
            handleOpenNewAndroidApp(); // Show the popup if not shown before
        }
    }, []);

    // Function to close the maintenance popup
    const handleCloseMaintenancePopup = () => {
        setShowMaintenancePopup(false);
        // Set a flag in ls-cache so next time we know it's been closed
        lscache.set('maintenancePopupClosed', true, 1440);
    };

    const { featuredPromise, newsPromise, topRatedPromise, latestPromise, ickeBytesPromise } = useLoaderData() as {
        featuredPromise: Promise<ICarouselContentDTO[]>;
        newsPromise: Promise<ICarouselDTO>;
        topRatedPromise: Promise<ICarouselDTO | null>;
        latestPromise: Promise<ICarouselDTO | null>;
        ickeBytesPromise: Promise<ICarouselDTO | null>;
    };

    const authCtx = useContext(AuthContext);
    const { width } = useWindowDimensions();
    const [categoriesCarousel, setCategoriesCarousel] = useState<ICarouselDTO>();
    const [customCarousels, setCustomCarousels] = useState<ICarouselDTO[]>([]);
    const [hasCategories, setHasCategories] = useState<boolean>(false);
    const [hasCustom, setHasCustom] = useState<boolean>(false);
    const hasMore = !hasCustom || !hasCategories;

    const CustomCarousels = async () => {
        const result = await GetCustomCarousels(authCtx.UserDetails.CurrentCountryCode, authCtx.UserDetails.AspNetUserId);
        setHasCustom(true);

        if (result === null || result instanceof AxiosError || result.length <= 0) {
            return;
        }
        setCustomCarousels(result);
    };

    const Categories = async () => {
        const result = await GetCategories(authCtx.UserDetails.AspNetUserId);
        setHasCategories(true);

        if (result === null || result === undefined || result instanceof AxiosError || result.Content === null || result.Content.length <= 0) {
            return;
        }
        setCategoriesCarousel(result);
    };

    const GetCarousels = async () => {
        if (!hasCustom) {
            await CustomCarousels();
        }

        if (!hasCategories) {
            await Categories();
        }
    };

    const loadMoreLoader = (paddingTop: number) => {
        return (
            <CarouselObject>
                <BrowseCarousel>
                    <HeadingLoader paddingTop={`${paddingTop}`} />
                    <ListContainer>
                        {[...Array(5)].map((_, i) => (
                            <VideoSingleLoader paddingTop="8" key={i} />
                        ))}
                    </ListContainer>
                </BrowseCarousel>
            </CarouselObject>
        );
    };

    const daysOfWeek = ['Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Friday', 'Sunday'];

    return (
        <>
            <UserOnboarding />

            {isNewAndroidAppVisible && (
                <NewAndroidAppPopUp onClose={handleCloseNewAndroidApp} />
            )}

            <Suspense fallback={<FeaturedSliderLoader />}>
                <Await resolve={featuredPromise}>
                    {(data) => {
                        const featured = data as ICarouselContentDTO[];
                        return (
                            <FeaturedCarousel
                                content={featured}
                                allowedContent={[ContentType.Series, ContentType.Episode, ContentType.Film]}
                            />
                        );
                    }}
                </Await>
            </Suspense>

            <Container>
                <BrowseContent>
                    <InfiniteScroll
                        dataLength={0}
                        hasMore={hasMore}
                        next={GetCarousels}
                        loader={loadMoreLoader(32)}
                        scrollThreshold={0.5}
                    >
                        <CarouselContainer>
                            <Suspense fallback={loadMoreLoader(0)}>
                                <Await resolve={newsPromise}>
                                    {(data) => {
                                        const news = data as ICarouselDTO;
                                        return (
                                            <CarouselObject key={"c" + news?.Title}>
                                                <Heading type={HeadingType.H3}>Weekly Shows</Heading>
                                                <PortraitImageContainer>
                                                    {news.Content.map((content, index) => (
                                                        <CarouselItemPortrait
                                                            key={index.toString() + content.Id}
                                                            Content={content}
                                                            releaseDayOfWeek={daysOfWeek[index % daysOfWeek.length]}
                                                        />
                                                    ))}
                                                </PortraitImageContainer>
                                            </CarouselObject>
                                        );
                                    }}
                                </Await>
                            </Suspense>

                            <Suspense fallback={loadMoreLoader(16)}>
                                <Await resolve={ickeBytesPromise}>
                                    {(ickeBytesData) => {
                                        const ickeBytes = ickeBytesData as ICarouselDTO;
                                        return (
                                            <Await resolve={topRatedPromise}>
                                                {(topRatedData) => {
                                                    const topRated = topRatedData as ICarouselDTO;
                                                    return (
                                                        <Await resolve={latestPromise}>
                                                            {(latestData) => {
                                                                const latest = latestData as ICarouselDTO;
                                                                const carousel = [topRated, latest, ickeBytes, ...customCarousels];
                                                                const carouselFiltered = carousel.filter(notEmpty);

                                                                return (
                                                                    <>
                                                                        {carouselFiltered
                                                                            .sort((x) => x.Position)
                                                                            .map((item) => (
                                                                                <CarouselObject key={"c" + item.Title}>
                                                                                    <BrowseCarousel>
                                                                                        <Heading type={HeadingType.H3}>
                                                                                            {item.Title}
                                                                                        </Heading>
                                                                                        <Carousel
                                                                                            arrows={true}
                                                                                            autoPlay={false}
                                                                                            bullets={false}
                                                                                            sliderPerView={width > 1115 ? 5 : 2}
                                                                                            spaceBetween={16}
                                                                                            loop={false}
                                                                                        >
                                                                                            {item.Content.map((content, index) => (
                                                                                                <SwiperSlide
                                                                                                    key={index.toString() + content.Id}
                                                                                                >
                                                                                                    <CarouselItem
                                                                                                        Content={content}
                                                                                                        disableOverlay={true}
                                                                                                    />
                                                                                                </SwiperSlide>
                                                                                            ))}
                                                                                        </Carousel>
                                                                                    </BrowseCarousel>
                                                                                </CarouselObject>
                                                                            ))}
                                                                    </>
                                                                );
                                                            }}
                                                        </Await>
                                                    );
                                                }}
                                            </Await>
                                        );
                                    }}
                                </Await>
                            </Suspense>

                            {categoriesCarousel !== null && categoriesCarousel !== undefined ? (
                                <CarouselObject key={"ca" + categoriesCarousel?.Title}>
                                    <Heading type={HeadingType.H3}>{categoriesCarousel.Title}</Heading>
                                    <BrowseCarousel>
                                        <PortraitImageContainer>
                                            {categoriesCarousel.Content.map((content, index) => (
                                                <CarouselItemPortrait
                                                    Content={content}
                                                    key={index.toString() + content.Id}
                                                    showOverlay={true}
                                                />
                                            ))}
                                        </PortraitImageContainer>
                                    </BrowseCarousel>
                                </CarouselObject>
                            ) : null}
                        </CarouselContainer>
                    </InfiniteScroll>

                    <BottomImageContainer>
                        <DownloadAppAd />
                    </BottomImageContainer>
                </BrowseContent>
            </Container>
        </>
    );
}

export default BrowseScreen;

export async function Loader() {
    const controller = new AbortController();
    const userDetails = await UserSnapshot;
    const response = AuthValidSubOnly(userDetails);

    if (response !== undefined) {
        return response;
    }

    async function InitFeatured() {
        const result = await GetFeaturedContent("US", userDetails.AspNetUserId, controller);
        return result === null || result === undefined || result instanceof AxiosError ? null : result;
    }

    async function GetNews() {
        const result = await GetNewAndCurrentAffairs(
            userDetails.AspNetUserId
        );

        if (
            result === null ||
            result === undefined ||
            result instanceof AxiosError ||
            result.Content === null ||
            result.Content.length <= 0
        ) {
            return null;
        }

        const manualOrder: string[] = [
            "Health Code with Jaymie Icke",
            "Classified with Richard Willett",
            "Gareth Icke Tonight",
            "The Dot Connector with David Icke",
            "Wake Up Call with Kristen Bitting",
            "WTAF With Rich & Gaz",
        ];

        // Sort Content by custom order (above titles)
        const sortedData = result.Content.sort((a, b) => {
            return manualOrder.indexOf(a.Title) - manualOrder.indexOf(b.Title);
        });

        return result;
    }

    async function TopRated() {
        const result = await GetTopRatedCarousel(userDetails.CurrentCountryCode, userDetails.AspNetUserId);
        return result === null || result === undefined || result instanceof AxiosError || result.Content === null || result.Content.length <= 0 ? null : result;
    }

    async function Latest() {
        const result = await GetLatest(userDetails.CurrentCountryCode, userDetails.AspNetUserId);
        return result === null || result === undefined || result instanceof AxiosError || result.Content === null || result.Content.length <= 0 ? null : result;
    }

    async function IckeBytes() {
        const result = await GetEpisodesByYear(166, userDetails.CurrentCountryCode, "", 30, 0, 0, controller, 'd');
        return result === null || result === undefined || result instanceof AxiosError || result.Content === null || result.Content.length <= 0 ? null : result;
    }

    SetTitle("Browse");

    return defer({
        featuredPromise: InitFeatured(),
        newsPromise: GetNews(),
        topRatedPromise: TopRated(),
        latestPromise: Latest(),
        ickeBytesPromise: IckeBytes(),
    });
}
