import axios, { AxiosError } from "axios";
import config from "../Constants/Config";
import { ICampaignDTO } from "../Models/DTOs/ICampaignDTO";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.campaign;

/**
 * Submit campaign emails.
 */
export const SubmitCampaignEmails = async (
    campaignDTO: ICampaignDTO
): Promise<{
    message: string;
    remainingEmails: number;
    emailAddressesAdded: string[];
    emailAddressesRefused: string[];
    campaignPopupDescriptiveText:string;
    campaignPopupCounterText:string;
}> => {
    try {
        const response = await axios.post(
            `${BACKEND_URL}/CampaignEmails`,
            campaignDTO,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return {
            message: response.data.message,
            remainingEmails: response.data.RemainingEmailCount,
            emailAddressesAdded: response.data.EmailAddressesAdded,
            emailAddressesRefused: response.data.EmailAddressesRefused,
            campaignPopupDescriptiveText:response.data.CampaignPopupDescriptiveText,
            campaignPopupCounterText:response.data.CampaignPopupCounterText
        };
    } catch (error) {
        handleAxiosError(error, "Error submitting campaign emails");
    }
};

/**
 * Fetch general campaign details.
 */
export const GetCampaignDetailsHeader = async (): Promise<{
    CampaignID: number;
    CampaignMaxEmails: number;
    CampaignTitle: string;
    CampaignEndDate: string; 
    CampaignAccountsPageHeader: string;
    CampaignAccountsPageDescription: string;
    CampaignAccountsPageLink: string;
    CampaignThemeId: number;
}> => {
    try {
        const response = await axios.get<{
            CampaignID: number;
            CampaignMaxEmails: number;
            CampaignTitle: string;
            CampaignEndDate: string;
            CampaignAccountsPageHeader: string;
            CampaignAccountsPageDescription: string;
            CampaignAccountsPageLink: string;
            CampaignThemeId: number;
        }>(`${BACKEND_URL}/GetCampaignDetailsHeader`);

        return response.data;
    } catch (error) {
        handleAxiosError(error, "Error fetching campaign details by ID");
    }
};

export const GetCampaignDetailsHeaderByCampaignID = async (
    Id: number
): Promise<{
    CampaignID: number;
    CampaignMaxEmails: number;
    CampaignTitle: string;
    CampaignEndDate: string;
    CampaignAccountsPageHeader:string;
    CampaignAccountsPageDescription:string;
    CampaignAccountsPageLink:string;
}> => {
    try {
        const response = await axios.get<{
            CampaignID: number;
            CampaignMaxEmails: number;
            CampaignTitle: string;
            CampaignEndDate: string;
            CampaignAccountsPageHeader:string;
            CampaignAccountsPageDescription:string;
            CampaignAccountsPageLink:string;
        }>(`${BACKEND_URL}/GetCampaignDetailsHeaderByUserID`, {
            params: {
                Id,
            },
        });

        return response.data;
    } catch (error) {
        handleAxiosError(error, "Error fetching campaign details header by user ID");
        throw error; // Rethrow error after handling
    }
};

export const GetCampaignDetailsByUserID = async (
    userId: string,
    campaignId: number
): Promise<{
    CampaignID: number;
    CampaignMaxEmails: number;
    CampaignTitle: string;
    CampaignEndDate: string;
    RemainingEmailsCount: number;
    EmailAddressesAdded: string;
    EmailAddressesRefused: string;
}> => {
    try {
        const response = await axios.get<{
            CampaignID: number;
            CampaignMaxEmails: number;
            CampaignTitle: string;
            CampaignEndDate: string;
            RemainingEmailsCount: number;
            EmailAddressesAdded: string;
            EmailAddressesRefused: string;
        }>(`${BACKEND_URL}/GetCampaignDetailsByUserID`, {
            params: {
                userId,
                campaignId,
            },
        });

        return response.data;
    } catch (error) {
        handleAxiosError(error, "Error fetching campaign details by user ID and campaign ID");
    }
};

/**
 * Handles Axios-specific errors with custom messaging.
 */
function handleAxiosError(error: AxiosError | any, defaultMessage: string): never {
    if (axios.isAxiosError(error)) {
        console.error(defaultMessage, error.response?.data || error.message);
        throw new Error(error.response?.data?.message || error.message);
    } else {
        console.error("Unexpected error:", error);
        throw error;
    }
}