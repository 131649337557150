import styled from "styled-components";

/**
 * Available Icons:
 *
 * EyeOff
 * Eye
 * Loader Circle
 * Alert Error
 * Store
 * SM58 (Microphone)
 * TV
 * Mobile Phone
 * Star Hollow
 * Play Button Circled
 * Translation
 * Pen And Paper
 * Mug Hot
 * Refresh Checkmark
 * Calendar
 * Shop Tag
 * Info Bubble
 * Double Column
 * Triple Column
 * Browser
 * List Checkmarks
 * List Bullet
 * Time Backwards
 * Pen Writing
 * Shirt
 * News
 * Chat Bubbles
 * Chat Bubbles Filled
 * IconExternalLink
 * IconTime
 * IconSurprise
 * IconArrowRight
 * IconThumbUp
 * IconThumbDown
 * IconShare
 * IconEmailSharpCorners
 * IconCopyLink
 * IconSearchMagnifier
 * IconAlertBell
 * IconFilters (Looks like audio faders)
 * IconExit (logout)
 * IconSpinLoader (Add animation-name: spin;)
 * IconCheckMark
 * IconClose (X)
 * IconCloseAlt
 * IconLifeRing (the floaty on boats)
 * IconGear
 * IconProfile (silhouette of person)
 * IconProfileThin
 * IconQuestionThin
 * IconQuestionFilled
 * IconReferAFriend
 * IconReferAFriendTree
 * IconAccepted
 * IconRefused
 * IconEasterEggs
 * */

const StyledIcon = styled.svg`
    width: 100%;
    height: 100%;
`;

export const IconEyeOff = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                fillRule="nonzero"
                d="M17.069 6.546l2.684-2.359A.753.753 0 0121 4.75a.748.748 0 01-.254.562l-16.5 14.501a.748.748 0 11-.993-1.125l2.438-2.143c-1.414-1.132-2.627-2.552-3.547-4.028a1 1 0 01.001-1.034C4.256 8.093 7.92 5 11.998 5c1.815 0 3.536.593 5.071 1.546zm2.319 1.83a16.03 16.03 0 012.474 3.117 1 1 0 010 1.014C19.794 15.91 16.141 19 11.998 19c-1.297 0-2.553-.313-3.729-.849l1.247-1.096a7.33 7.33 0 002.482.445c3.516 0 6.576-2.622 8.413-5.5a14.643 14.643 0 00-2.145-2.637zm-3.434 3.019a4.001 4.001 0 01-5.061 4.449l1.58-1.389a2.503 2.503 0 001.901-1.671zm-.058-3.818c-1.197-.67-2.512-1.077-3.898-1.077-3.465 0-6.533 2.632-8.404 5.5.853 1.308 1.955 2.567 3.231 3.549l1.728-1.519a4.001 4.001 0 015.902-5.187zm-2.6 2.285a2.5 2.5 0 00-3.583 3.149z"
            ></path>
        </StyledIcon>
    )
};

export const IconEye = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                fillRule="nonzero"
                d="M11.998 5C7.92 5 4.256 8.093 2.145 11.483a1 1 0 00-.001 1.034C4.256 15.907 7.92 19 11.998 19c4.143 0 7.796-3.09 9.864-6.493a1 1 0 000-1.014C19.794 8.09 16.141 5 11.998 5zm8.413 7c-1.837 2.878-4.897 5.5-8.413 5.5-3.465 0-6.532-2.632-8.404-5.5 1.871-2.868 4.939-5.5 8.404-5.5 3.518 0 6.579 2.624 8.413 5.5zM12 8c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"
            ></path>
        </StyledIcon>
    )
};

export const IconLoaderCircle = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm8 12a8 8 0 11-16 0 8 8 0 0116 0zM1 12C1 5.935 5.935 1 12 1v2c-4.962 0-9 4.038-9 9a8.972 8.972 0 002.639 6.361l-1.414 1.414.015.014A10.97 10.97 0 011 12z"></path>
        </svg>
    )
};

export const IconAlertError = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                fillRule="nonzero"
                d="M12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497S20.5 6.846 20.5 11.536s-3.808 8.498-8.498 8.498zm0-6.5a.75.75 0 01-.75-.75v-5.5a.75.75 0 011.5 0v5.5a.75.75 0 01-.75.75zm-.002 3a1 1 0 100-2 1 1 0 000 2z"
            ></path>
        </svg>
    )
};

export const IconStore = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M19.899 19.899c.168.145.072.381-.132.618-.156.183-.305.283-.434.283l-.156-.06c-.157-.136-.089-.383.123-.625.144-.166.302-.276.44-.276l.159.06zM18.478 14H14v4.48L19.518 24 24 19.52 18.478 14zm-.447 3.364c.21-.245.479-.379.745-.379.188 0 .374.067.538.208.403.346.376.835.033 1.234-.234.272-.502.39-.752.39a.818.818 0 01-.534-.202c-.354-.307-.407-.813-.03-1.251zM16 16.75a.75.75 0 110-1.5.75.75 0 010 1.5zm1.25 2.866l3.417-1.262.295.252-3.42 1.259-.292-.249zm2.944 1.225c-.233.272-.5.39-.75.39a.818.818 0 01-.533-.202c-.353-.302-.405-.809-.032-1.25.21-.245.479-.379.745-.379.188 0 .374.066.539.208.402.345.377.834.031 1.233zm-1.864-2.516c-.158-.136-.09-.383.119-.627.144-.166.304-.275.442-.275l.157.059c.168.145.075.384-.128.621-.156.182-.307.283-.436.283l-.154-.061zM12 8c1.066 0 2-.936 2-2.002V4.9L12.953 0h-1.906L10 4.902V6c0 1.067.934 2 2 2zm3-2c0 1.067.934 2 2 2s1.998-.833 1.998-1.9V5.002L16.004 0h-1.943L15 4.902V6zm5 0c0 1.067.934 2 2 2s2-.865 2-1.932V4.971L19.127 0h-2.014L20 4.902V6zm-8.312 13H3v-7h18v1.693l2 1.999V10H1v14h15.69l-5.002-5zM4 6.068V4.971L6.887 0H4.873L0 4.971v1.098C0 7.135.934 8 2 8s2-.865 2-1.932zm1 0C5 7.135 5.934 8 7 8s2-.865 2-1.932V4.971L9.939 0H7.996L5 4.971v1.097z"></path>
        </svg>
    )
};

export const IconSM58 = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path fill={props?.iconFill}
                  d="M3.979 15.045c-1.468.978-2.168 2.263-2.231 3.955H0c.069-2.346 1.1-4.186 3.153-5.497l.826 1.542zM19.339 1c-1.852 0-3.198.966-4.138 2.619l3.545 6.618c3.4.222 5.254-2.15 5.254-4.575A4.654 4.654 0 0019.339 1zM4.472 11.728l1.557 2.906 10.539-4.229-2.716-5.07-9.38 6.393zm4.765 3.773c.633.619.764 1.648.764 2.558V23H12v-5.097c0-1.776.662-3.024 1.735-4.207l-4.498 1.805z"></path>
        </svg>
    )
};

export const IconTV = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path fill={props?.iconFill}
                  d="M22 4v12H2V4h20zm2-2H0v16h24V2zM7 20c1 1.194 2.862 2 5 2s4-.806 5-2H7z"></path>
        </svg>
    )
};

export const IconMobilePhone = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M19 2a2 2 0 00-2-2H7a2 2 0 00-2 2v20a2 2 0 002 2h10a2 2 0 002-2V2zm-8.5 0h3a.5.5 0 010 1h-3a.5.5 0 010-1zM12 22a1 1 0 110-2 1 1 0 010 2zm5-3H7V4.976h10V19z"></path>
        </svg>
    )
};

export const IconStarHollow = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M11.322 2.923a.754.754 0 011.356 0l2.65 5.44 6.022.829a.749.749 0 01.419 1.283c-1.61 1.538-4.382 4.191-4.382 4.191l1.069 5.952a.751.751 0 01-1.097.793L12 18.56l-5.359 2.851a.751.751 0 01-1.097-.793l1.07-5.952-4.382-4.191a.75.75 0 01.419-1.283l6.021-.829 2.65-5.44zM12 4.956L9.639 9.748l-5.246.719 3.848 3.643-.948 5.255L12 16.86l4.707 2.505-.951-5.236 3.851-3.662-5.314-.756z"
            ></path>
        </svg>
    )
};

export const IconPlayButtonCircled = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M2.009 12.002c0-5.517 4.48-9.997 9.998-9.997s9.998 4.48 9.998 9.997c0 5.518-4.48 9.998-9.998 9.998s-9.998-4.48-9.998-9.998zm1.5 0c0 4.69 3.808 8.498 8.498 8.498s8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497-8.498 3.807-8.498 8.497zm6.711-4.845a.75.75 0 00-1.211.592v8.501a.75.75 0 001.212.591l5.498-4.258a.746.746 0 00-.001-1.183zm.289 7.564V9.275l3.523 2.718z"
            ></path>
        </svg>
    )
};

export const IconArrowSmall = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M13.789 7.155A.75.75 0 0115 7.747v8.501a.75.75 0 01-1.212.591L8.29 12.581a.746.746 0 01.001-1.183zm-.289 7.563V9.272l-3.522 2.719z"
            ></path>
        </svg>
    )
};

export const IconPenAndPaper = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M14.776 18.689l7.012-7.012a.763.763 0 00.217-.532.715.715 0 00-.218-.515l-2.423-2.415a.74.74 0 00-1.045 0l-7.027 6.996c-.442 1.371-1.158 3.586-1.265 3.952-.125.433.199.834.573.834.41 0 .696-.099 4.176-1.308zm-2.258-2.392l1.17 1.171c-.704.232-1.275.418-1.729.566zm.968-1.154l5.356-5.331 1.347 1.342-5.346 5.347zM9 13.75a.772.772 0 00-.75-.75h-5.5c-.394 0-.75.348-.75.75s.356.75.75.75h5.5c.394 0 .75-.348.75-.75zm5-3a.772.772 0 00-.75-.75H2.75c-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75zm0-3a.772.772 0 00-.75-.75H2.75c-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75zm0-3a.772.772 0 00-.75-.75H2.75c-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75z"
            ></path>
        </svg>
    )
};

export const IconTranslation = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M21 10h-8a3 3 0 00-3 3v8a3 3 0 003 3h8a3 3 0 003-3v-8a3 3 0 00-3-3zm-1.865 11l-.66-1.846h-3L14.812 21h-1.66l3.041-8h1.602l3.053 8h-1.713zm-2.153-6.137l1.051 3.018H15.93l1.052-3.018zM5.145 7.886c.038.581.104 1.116.194 1.593-1.224.224-1.274-.984-.194-1.593zM14 3v5h-1c-.743 0-1.442.173-2.076.465-.196-.806-.783-1.53-1.679-1.964.077-.298.123-.522.167-.744l-1.35-.262c-.023.113-.063.328-.136.614a5.876 5.876 0 00-1.431-.025 17.1 17.1 0 01.06-.963h1.507V3.842H6.736c.065-.326.121-.521.176-.705l-1.317-.396a8.98 8.98 0 00-.259 1.101H3.832V5.12h1.341c-.036.414-.057.842-.064 1.275C3.629 6.939 3 7.976 3 8.916c0 1.11.876 2.086 2.261 1.96 1.72-.156 2.869-1.456 3.542-3.053.885.536.838 1.161.773 1.552A4.974 4.974 0 008 13v1H3a3 3 0 01-3-3V3a3 3 0 013-3h8a3 3 0 013 3zM6.5 7.467a4.79 4.79 0 01.969-.026c-.214.476-.496.948-.855 1.319-.055-.396-.093-.83-.114-1.293z">
            </path>
        </svg>
    )
};

export const IconMugHot = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M14.884 10c-.439 5.493-1.803 9.418-3.087 12H7.205c-1.692-3.396-2.728-7.419-3.09-12h10.769zM17 8H2c.258 7.569 2.143 12.68 4 16h7c1.812-3.219 3.745-8.496 4-16zm1.088 2a44.864 44.864 0 01-.192 2h1.707c-.51 1.822-1.246 3.331-2.539 4.677a35.202 35.202 0 01-.939 3.229C19.386 17.739 21.681 13.517 22 10h-3.912zm-7.714-3.001c4.737-4.27-.98-4.044.117-6.999-3.783 3.817 1.409 3.902-.117 6.999zM7.594 7c3.154-2.825-.664-3.102.087-5.099C5.039 4.688 8.631 4.76 7.594 7z"></path>
        </svg>
    )
};

export const IconRefreshCheckmark = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={props?.iconFill}
                fillRule="nonzero"
                d="M2.164 10.201c.055-.298.393-.734.934-.59a.752.752 0 01.543.86C3.564 11 3.5 11.324 3.5 12c0 4.47 3.601 8.495 8.502 8.495 2.173 0 4.241-.84 5.792-2.284l-1.251-.341a.751.751 0 01.389-1.449l3.225.864a.752.752 0 01.53.919l-.875 3.241a.75.75 0 11-1.449-.388l.477-1.77a10.028 10.028 0 01-6.838 2.708c-5.849 0-9.968-4.8-10.002-9.93a9.987 9.987 0 01.164-1.864zm5.396 2.857l2.924 2.503c.142.128.321.19.499.19a.746.746 0 00.552-.242L16.488 10a.747.747 0 00-.552-1.249.751.751 0 00-.554.243l-4.453 4.962-2.371-2.011a.746.746 0 10-.998 1.113zm14.276.743c-.054.298-.392.734-.933.59a.75.75 0 01-.543-.86c.068-.48.139-.848.139-1.53 0-4.479-3.609-8.495-8.5-8.495a8.526 8.526 0 00-5.794 2.285l1.251.341a.75.75 0 11-.388 1.448l-3.225-.864a.75.75 0 01-.53-.918l.875-3.241a.75.75 0 111.449.388l-.478 1.769a10.034 10.034 0 016.839-2.708C17.863 2.006 22 6.836 22 12.001a9.97 9.97 0 01-.164 1.8z"
            ></path>
        </svg>
    )
};

export const IconCalendar = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={props?.iconFill}
                d="M17 3V1a1 1 0 112 0v2a1 1 0 11-2 0zM5 4a1 1 0 001-1V1a1 1 0 10-2 0v2a1 1 0 001 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zM9.763 21H2V8h19v1.763c.727.33 1.399.757 2 1.268V2h-3v1a2.007 2.007 0 01-2.658 1.894C16.511 4.626 16 3.783 16 2.91V2H7v1a2.007 2.007 0 01-2.658 1.894C3.511 4.626 3 3.783 3 2.91V2H0v21h11.031a8.525 8.525 0 01-1.268-2z"></path>
        </svg>
    )
};

export const IconShopTag = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M12.628 21.412l5.969-5.97 1.458 3.71L7.715 24 2.907 11.762l9.721 9.65zM11.352 0H2v9.453L12.625 20 22 10.625 11.352 0zm4.025 9.476c-.415-.415-.865-.617-1.378-.617-.578 0-1.227.241-2.171.804-.682.41-1.118.584-1.456.584-.361 0-1.083-.408-.961-1.218.052-.345.25-.697.572-1.02.652-.651 1.544-.848 2.276-.106l.744-.744c-.476-.476-1.096-.792-1.761-.792-.566 0-1.125.227-1.663.677l-.626-.627-.698.699.653.652c-.569.826-.842 2.021.076 2.938 1.011 1.011 2.188.541 3.413-.232.6-.379 1.083-.563 1.475-.563.589 0 1.18.498 1.078 1.258-.052.386-.26.763-.621 1.122-.451.451-.904.679-1.347.679-.418 0-.747-.192-1.049-.462l-.739.739c.463.458 1.082.753 1.735.753.544 0 1.087-.201 1.612-.597l.54.538.697-.697-.52-.521c.743-.896 1.157-2.209.119-3.247zM5.699 2a1.7 1.7 0 110 3.399 1.7 1.7 0 010-3.399z"></path>
        </svg>
    )
};

export const IconInformationBubble = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M2 4v16.002a1 1 0 001.625.781l4.725-3.781H21a1 1 0 001-1V4a1 1 0 00-1-1H3a1 1 0 00-1 1zm18.5 11.502H7.823L3.5 18.962V4.5h17zm-8.502-6.5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5a.75.75 0 01.75-.75zm.002-3a1 1 0 110 2 1 1 0 010-2z"
            ></path>
        </svg>
    )
};

export const IconHeartHollow = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M7.234 3.004C4.582 3.004 2 4.833 2 8.181c0 3.725 4.345 7.727 9.303 12.54a.996.996 0 001.394 0C17.674 15.89 22 11.907 22 8.181c0-3.353-2.58-5.168-5.229-5.168A5.692 5.692 0 0012 5.567a5.686 5.686 0 00-4.766-2.563zm0 1.5c1.99.001 3.202 1.353 4.155 2.7a.752.752 0 001.223.003c.955-1.339 2.19-2.694 4.159-2.694 1.796 0 3.729 1.148 3.729 3.668 0 2.671-2.881 5.673-8.5 11.127-5.454-5.285-8.5-8.389-8.5-11.127 0-1.125.389-2.069 1.124-2.727.673-.604 1.625-.95 2.61-.95z"
            ></path>
        </svg>
    )
};


export const IconDoubleColumn = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M11.3 13.7c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1V19c0 .53.47 1 1 1h7.3c.53 0 1-.47 1-1zm10.7 0c0-.53-.47-1-1-1h-7.3c-.53 0-1 .47-1 1V19c0 .53.47 1 1 1H21c.53 0 1-.47 1-1zm-7.8.5h6.3v4.3h-6.3zm-10.7 0h6.3v4.3H3.5zM22 5c0-.53-.47-1-1-1h-7.3c-.53 0-1 .47-1 1v5.3c0 .53.47 1 1 1H21c.53 0 1-.47 1-1zM11.3 5c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1v5.3c0 .53.47 1 1 1h7.3c.53 0 1-.47 1-1zm2.9.5h6.3v4.3h-6.3zm-10.7 0h6.3v4.3H3.5z"
            ></path>
        </svg>
    )
};


export const IconTripleColumn = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M14.865 16.6c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1V19c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zm-7.135 0c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1V19c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zm14.27 0c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1V19c0 .53.47 1 1 1H21c.53 0 1-.47 1-1zm-11.365.5h2.73v1.4h-2.73zm-7.135 0h2.73v1.4H3.5zm14.27 0h2.73v1.4h-2.73zm-2.905-6.3c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zm-7.135 0c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zm14.27 0c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1H21c.53 0 1-.47 1-1zm-11.365.5h2.73v1.4h-2.73zm-7.135 0h2.73v1.4H3.5zm14.27 0h2.73v1.4h-2.73zM14.865 5c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zM7.73 5c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1h3.73c.53 0 1-.47 1-1zM22 5c0-.53-.47-1-1-1h-3.73c-.53 0-1 .47-1 1v2.4c0 .53.47 1 1 1H21c.53 0 1-.47 1-1zm-11.365.5h2.73v1.4h-2.73zM3.5 5.5h2.73v1.4H3.5zm14.27 0h2.73v1.4h-2.73z"
            ></path>
        </svg>
    )
};

export const IconBrowser = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M21 4c0-.478-.379-1-1-1H4c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-1.5 5.505V19.5h-15V9.505zm0-1.5h-15V4.5h15zm-2.25-2.5a.75.75 0 110 1.5.75.75 0 010-1.5zm-3 0a.75.75 0 110 1.5.75.75 0 010-1.5z"
            ></path>
        </svg>
    )
};

export const IconListCheckmarks = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                fillRule="nonzero"
                d="M22 17.75a.75.75 0 00-.75-.75h-9.5a.75.75 0 000 1.5h9.5a.75.75 0 00.75-.75zM2.251 16.373l2.172 1.936a.754.754 0 001.059-.053l3.677-4.02a.751.751 0 00-.556-1.257.757.757 0 00-.558.244l-3.174 3.47-1.616-1.44a.753.753 0 00-1.004 1.12zM18 13.75a.75.75 0 00-.75-.75h-5.5a.75.75 0 000 1.5h5.5a.75.75 0 00.75-.75zM2.251 8.394l2.172 1.936a.754.754 0 001.059-.053l3.677-4.02A.752.752 0 008.603 5a.757.757 0 00-.558.244L4.871 8.715l-1.616-1.44A.754.754 0 002 7.835c0 .206.084.411.251.559zM20 9.75a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h7.5a.75.75 0 00.75-.75zm2-4a.75.75 0 00-.75-.75h-9.5a.75.75 0 000 1.5h9.5a.75.75 0 00.75-.75z"
            ></path>
        </svg>
    )
};

export const IconListBullet = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M3.3 15.4a1.301 1.301 0 010 2.6 1.301 1.301 0 010-2.6zM6 17.25a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM3.3 10.7a1.301 1.301 0 010 2.6 1.301 1.301 0 010-2.6zM6 12a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H6.75A.75.75 0 016 12zM3.3 6a1.301 1.301 0 010 2.6 1.301 1.301 0 010-2.6zm2.7.75A.75.75 0 016.75 6h14.5a.75.75 0 010 1.5H6.75A.75.75 0 016 6.75z"
            > </path>
        </svg>
    )
};

export const IconTimeBackwards = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M3.503 6.726A9.996 9.996 0 0111.998 2c5.518 0 9.997 4.48 9.997 9.997 0 5.519-4.479 9.999-9.997 9.999-5.245 0-9.553-4.048-9.966-9.188-.024-.302.189-.811.749-.811.391 0 .715.3.747.69.351 4.369 4.012 7.809 8.47 7.809 4.69 0 8.497-3.808 8.497-8.499 0-4.689-3.807-8.497-8.497-8.497a8.495 8.495 0 00-7.206 3.995l1.991.005a.75.75 0 010 1.5H2.75A.75.75 0 012 8.25V4.201a.75.75 0 011.5 0zm7.487.021l.007 5.563c0 .288.165.55.424.675l3.978 1.928a.75.75 0 10.652-1.35l-3.555-1.725-.006-5.093a.75.75 0 00-1.5.002z"
            > </path>
        </svg>
    )
};

export const IconPenWriting = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path
                fillRule="nonzero"
                fill={props?.iconFill}
                d="M19 20.25a.772.772 0 00-.75-.75H3.75c-.394 0-.75.348-.75.75s.356.75.75.75h14.5c.394 0 .75-.348.75-.75zm-7.403-3.398l9.124-9.125A.982.982 0 0021 7.043a.92.92 0 00-.28-.662l-3.115-3.104a.95.95 0 00-1.344 0L7.118 12.38c-.569 1.763-1.555 4.823-1.626 5.081a.867.867 0 00-.029.224c0 .461.349.848.765.848.511 0 .991-.189 5.369-1.681zm-3.27-3.342l2.137 2.137-3.168 1.046zm.955-1.166l7.651-7.616 2.335 2.327-7.637 7.638z"
            > </path>
        </svg>
    )
};

export const IconShirt = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={props?.iconFill}
                d="M20.207 8.53l-2.193-1.405v15.884H5.99V7.155L3.795 8.473l-2.426-2.92L6.202 1h1.424c.51 1.754 2.018 3.5 4.377 3.5 2.392 0 3.876-1.699 4.381-3.5h1.42l4.826 4.586-2.423 2.944zM15.349 1c-.466 1.322-1.604 2.5-3.346 2.5-1.73 0-2.868-1.178-3.342-2.5h6.688zm2.853-1H5.805L0 5.469l3.574 4.303 1.416-.85v15.087h14.024V8.953l1.402.898 3.577-4.349L18.202 0z"></path>
        </svg>
    )
};

export const IconNews = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={ props?.iconFill }
                d="M22 23H2a2 2 0 01-2-2V3h1v18.094c0 .276.183.601.508.607.278.005.492-.23.492-.557V1h22v20c0 1.242-1.042 2-2 2zm1-21H3v20h19c.48 0 1-.379 1-1V2zm-2 17v1H5v-1h16zm0-3v1H5v-1h16zm0-3v1h-6v-1h6zm-8 1H5v-4h8v4zm-1-3H6v2h6v-2zm9-1v1h-6v-1h6zm-5.254-2c.722 0 1.236-.401 1.236-1.077 0-.741-.566-.927-1.07-1.087-.518-.165-.599-.275-.599-.436 0-.14.119-.265.357-.265.304 0 .461.155.575.406l.642-.396c-.243-.516-.671-.791-1.217-.791-.576 0-1.118.39-1.118 1.066 0 .672.485.922.98 1.067.499.146.689.231.689.446 0 .135-.09.286-.451.286-.376 0-.581-.196-.699-.486l-.657.4c.186.516.633.867 1.332.867zm2.686 0c.723 0 1.236-.401 1.236-1.077 0-.741-.566-.927-1.07-1.087-.518-.165-.599-.275-.599-.436 0-.14.119-.265.357-.265.304 0 .461.155.575.406l.642-.396c-.242-.516-.67-.791-1.217-.791-.575 0-1.117.39-1.117 1.066 0 .672.485.922.979 1.067.499.146.69.231.69.446 0 .135-.091.286-.452.286-.376 0-.58-.196-.699-.486l-.656.4c.185.516.632.867 1.331.867zm-11.34-.07h-.76V4.424h1.26c.656 0 1.165.536 1.165 1.202 0 .666-.509 1.202-1.165 1.202h-.5V7.93zm7.037-2.735h-1.307v.581h1.188v.761h-1.188v.622h1.331v.771h-2.092V4.424h2.068v.771zM9.898 7.93h-.761V4.424h1.331c.633 0 1.141.536 1.141 1.202 0 .431-.247.821-.613 1.017l.708 1.287h-.817l-.628-1.152h-.361V7.93zM7.592 5.175h-.5v.902h.5c.233 0 .404-.196.404-.451 0-.256-.171-.451-.404-.451zm2.876 0h-.57v.902h.57c.209 0 .381-.196.381-.451 0-.256-.172-.451-.381-.451z"></path>
        </svg>
    )
};

export const IconChat = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            { props?.defs ? props.defs : null }
            <path
                fill={props?.iconFill}
                d="M20 15a1 1 0 11-2 0 1 1 0 012 0m-3 0a1 1 0 11-2 0 1 1 0 012 0m-3 0a1 1 0 11-2 0 1 1 0 012 0m5.415 4.946c-1 .256-1.989.482-3.324.482-3.465 0-7.091-2.065-7.091-5.423 0-3.128 3.14-5.672 7-5.672 3.844 0 7 2.542 7 5.672 0 1.591-.646 2.527-1.481 3.527l.839 2.686-2.943-1.272zM6.042 16.571l-4.389 1.896 1.256-4.012C1.788 13.114 1 11.79 1 9.756 1 5.479 5.262 2 10.5 2c5.018 0 9.128 3.194 9.467 7.222A9.266 9.266 0 0016 8.333c-4.199 0-8 2.797-8 6.672 0 .712.147 1.4.411 2.049-.953-.126-1.546-.272-2.369-.483M24 15.005c0-2.172-1.199-4.015-3.002-5.21L21 9.756C21 4.67 16.012 1 10.5 1 4.954 1 0 4.698 0 9.756a7.71 7.71 0 001.791 4.922L.047 20.25l6.078-2.625c.982.253 1.932.407 2.85.489 1.317 1.953 3.876 3.314 7.116 3.314 1.019 0 2.105-.135 3.242-.428l4.631 2-1.328-4.245A5.886 5.886 0 0024 15.005"></path>
        </svg>
    )
};

export const IconChatFilled = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M19.619 21.671c-5.038 1.227-8.711-1.861-8.711-5.167 0-3.175 3.11-5.467 6.546-5.467 3.457 0 6.546 2.309 6.546 5.467 0 1.12-.403 2.22-1.117 3.073-.029 1 .558 2.435 1.088 3.479-1.419-.257-3.438-.824-4.352-1.385zM8.908 16.504c0-4.117 3.834-7.467 8.546-7.467.886 0 1.74.119 2.544.338-.021-4.834-4.761-8.319-9.998-8.319-5.281 0-10 3.527-10 8.352 0 1.71.615 3.391 1.705 4.695.047 1.527-.851 3.718-1.661 5.313 2.168-.391 5.252-1.258 6.649-2.115.803.196 1.576.304 2.328.363a6.648 6.648 0 01-.113-1.16z"></path>

        </svg>
    )
};

export const ExternalLink = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M21 13v10H0V4h12v2H2v15h17v-8h2zm3-12H13.012l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07L24 12V1z"> </path>
        </StyledIcon>
    )
};

export const IconTime = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M18.513 7.119A6.306 6.306 0 0020 3.083V0H4v3.083c0 1.459.528 2.892 1.487 4.035l3.086 3.68c.567.677.571 1.625.009 2.306l-3.13 3.794A6.308 6.308 0 004 20.893V24h16v-3.107c0-1.44-.517-2.858-1.453-3.994l-3.13-3.794a1.782 1.782 0 01.009-2.306l3.087-3.68zm-4.639 7.257l3.13 3.794c.652.792.996 1.726.996 2.83h-1.061C16.146 18.983 12 16 12 16s-4.147 2.983-4.94 5H6c0-1.104.343-2.039.996-2.829l3.129-3.793a3.803 3.803 0 00-.019-4.864L7.02 5.833C6.36 5.048 6 4.097 6 2.999h12c0 1.101-.363 2.05-1.02 2.834l-3.087 3.68a3.804 3.804 0 00-.019 4.863z"> </path>
        </StyledIcon>
    )
};

export const IconSurprise = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            <path
                fillRule="nonzero"
                d="M12.002 21.534c5.518 0 9.998-4.48 9.998-9.998s-4.48-9.997-9.998-9.997c-5.517 0-9.997 4.479-9.997 9.997s4.48 9.998 9.997 9.998zm0-1.5c-4.69 0-8.497-3.808-8.497-8.498s3.807-8.497 8.497-8.497S20.5 6.846 20.5 11.536s-3.808 8.498-8.498 8.498zm0-6.5a.75.75 0 01-.75-.75v-5.5a.75.75 0 011.5 0v5.5a.75.75 0 01-.75.75zm-.002 3a1 1 0 100-2 1 1 0 000 2z"
            > </path>
        </StyledIcon>
    )
};

export const IconList = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            <path
                fillRule="nonzero"
                d="M10.5 17.25a.75.75 0 01.75-.75h10a.75.75 0 010 1.5h-10a.75.75 0 01-.75-.75zM9 13.7c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1V18c0 .53.47 1 1 1h5c.53 0 1-.47 1-1zm1.5-1.7a.75.75 0 01.75-.75h10a.75.75 0 010 1.5h-10a.75.75 0 01-.75-.75zM9 6c0-.53-.47-1-1-1H3c-.53 0-1 .47-1 1v4.3c0 .53.47 1 1 1h5c.53 0 1-.47 1-1zm1.5.75a.75.75 0 01.75-.75h10a.75.75 0 010 1.5h-10a.75.75 0 01-.75-.75z"
            > </path>
        </StyledIcon>
    )
};

export const IconArrowRight = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            <path
                fillRule="nonzero"
                d="M13.022 14.999v3.251a.751.751 0 001.27.544l6.396-6.069a.998.998 0 000-1.45l-6.396-6.068a.752.752 0 00-1.27.543v3.251h-9.02c-.531 0-1.002.47-1.002 1v3.998c0 .53.471 1 1.002 1z"
            > </path>
        </StyledIcon>
    )
};

export const IconThumbUp = (props: { iconFill: string }) => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path fill={ props.iconFill } d="M15.43 8.814C16.238 5.531 16.682 0 13.233 0c-1.861 0-2.35 1.668-2.833 3.329-1.971 6.788-5.314 7.342-8.4 7.743V21c3.503 0 5.584.729 8.169 1.842 1.257.541 3.053 1.158 5.336 1.158 2.538 0 4.295-.997 5.009-3.686.5-1.877 1.486-7.25 1.486-8.25 0-1.649-1.168-2.446-2.594-2.507-1.21-.051-2.87-.277-3.976-.743zm3.718 4.321l-1.394.167s-.609 1.109.141 1.115c0 0 .201.01 1.069-.027 1.082-.046 1.051 1.469.004 1.563l-1.761.099c-.734.094-.656 1.203.141 1.172 0 0 .686-.017 1.143-.041 1.068-.056 1.016 1.429.04 1.551-.424.053-1.745.115-1.745.115-.811.072-.706 1.235.109 1.141l.771-.031c.822-.074 1.003.825-.292 1.661-1.567.881-4.685.131-6.416-.614C8.72 20.041 6.521 19.072 4 19v-6c3.263-.749 6.329-2.254 8.321-9.113C13.219.795 14 1.956 14 4.461c0 2.071-.49 3.786-.921 5.533 1.061.543 3.371 1.402 6.12 1.556 1.055.059 1.025 1.455-.051 1.585z"> </path>
        </StyledIcon>
    )
};

export const IconThumbDown = (props: { iconFill: string }) => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path fill={ props.iconFill } d="M19.406 14.442c1.426-.06 2.594-.858 2.594-2.506 0-1-.986-6.373-1.486-8.25C19.8.997 18.043 0 15.505 0c-2.283 0-4.079.617-5.336 1.158C7.584 2.271 5.504 3 2 3v9.928c3.086.401 6.43.956 8.4 7.744.483 1.66.972 3.328 2.833 3.328 3.448 0 3.005-5.531 2.196-8.814 1.107-.466 2.767-.692 3.977-.744zm-.207-1.992c-2.749.154-5.06 1.013-6.12 1.556.431 1.747.921 3.462.921 5.533 0 2.505-.781 3.666-1.679.574C10.328 13.254 7.264 11.749 4 11V5c2.521-.072 4.72-1.041 6.959-2.005 1.731-.745 4.849-1.495 6.416-.614 1.295.836 1.114 1.734.292 1.661l-.771-.032c-.815-.094-.92 1.068-.109 1.141 0 0 1.321.062 1.745.115.976.123 1.028 1.607-.04 1.551-.457-.024-1.143-.041-1.143-.041-.797-.031-.875 1.078-.141 1.172 0 0 .714.005 1.761.099s1.078 1.609-.004 1.563c-.868-.037-1.069-.027-1.069-.027-.75.005-.874 1.028-.141 1.115l1.394.167c1.075.13 1.105 1.526.05 1.585z"> </path>
        </StyledIcon>
    )
};

export const IconShare = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M5 7a5 5 0 11-.001 10.001A5 5 0 015 7zm11.122 12.065A3.946 3.946 0 0016 20a4 4 0 104-4 3.974 3.974 0 00-2.935 1.301l-5.488-2.927a6.973 6.973 0 01-.943 1.764l5.488 2.927zM24 4a4 4 0 00-8 0c0 .324.049.634.122.935l-5.488 2.927c.395.535.713 1.127.943 1.764l5.488-2.927A3.974 3.974 0 0020 8a4 4 0 004-4z"> </path>
        </StyledIcon>
    )
};

export const IconEmailSharpCorners = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M0 3v18h24V3H0zm21.518 2L12 12.713 2.482 5h19.036zM2 19V7.183l10 8.104 10-8.104V19H2z"> </path>
        </StyledIcon>
    )
};

export const IconCopyLink = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M21.921 2.081a7.104 7.104 0 010 10.042l-3.84 3.839-2.121-2.122L19.799 10a4.101 4.101 0 00-.001-5.797 4.103 4.103 0 00-5.797-.001l-3.84 3.839L8.04 5.92l3.84-3.839a7.1 7.1 0 0110.041 0zM13.839 15.96l-3.84 3.839a4.104 4.104 0 01-5.799 0 4.104 4.104 0 010-5.797l3.84-3.84-2.121-2.121-3.84 3.84A7.1 7.1 0 0012.12 21.922l3.84-3.84-2.121-2.122z"> </path>
        </StyledIcon>
    )
};

export const IconSearchMagnifier = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M23.809 21.646l-6.205-6.205a9.68 9.68 0 001.857-5.711C19.461 4.365 15.096 0 9.73 0 4.365 0 0 4.365 0 9.73c0 5.366 4.365 9.73 9.73 9.73a9.678 9.678 0 005.487-1.698L21.455 24l2.354-2.354zM2.854 9.73c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877A6.884 6.884 0 012.854 9.73z"> </path>
        </StyledIcon>
    )
};

export const IconAlertBell = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M15 21c0 1.598-1.392 3-2.971 3S9 22.598 9 21h6zm.137-17.055a2.103 2.103 0 01-1.041-1.82v-.003C14.097.95 13.158 0 12 0S9.903.95 9.903 2.122v.003a2.1 2.1 0 01-1.041 1.82C4.194 6.654 6.877 15.66 2 17.251V19h20v-1.749c-4.877-1.591-2.193-10.598-6.863-13.306zM12 1a1.001 1.001 0 010 2 1 1 0 010-2zM5.549 17c1.189-1.667 1.605-3.891 1.964-5.815.447-2.39.869-4.648 2.354-5.509 1.38-.801 2.956-.76 4.267 0 1.485.861 1.907 3.119 2.354 5.509.359 1.924.775 4.148 1.964 5.815H5.549z"> </path>
        </StyledIcon>
    )
};

export const IconFilters = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M17 8a1.001 1.001 0 11-1 1c0-.551.448-1 1-1zm0-2a3 3 0 100 6 3 3 0 000-6zM7 12a3 3 0 100 6 3 3 0 000-6zm10-8c.343 0 .677.035 1 .101V2a1 1 0 10-2 0v2.101A4.988 4.988 0 0117 4zM7 10c.343 0 .677.035 1 .101V2a1 1 0 10-2 0v8.101A4.988 4.988 0 017 10zm10 4c-.343 0-.677-.035-1-.101V22a1 1 0 102 0v-8.101A4.988 4.988 0 0117 14zM7 20c-.343 0-.677-.035-1-.101V22a1 1 0 102 0v-2.101A4.988 4.988 0 017 20z"> </path>
        </StyledIcon>
    )
};

export const IconExit = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path d="M16 2v7h-2V4H2v16h12v-5h2v7H0V2h16zm2 9V7l6 5-6 5v-4H8v-2h10z"> </path>
        </StyledIcon>
    )
};

export const IconSpinLoader = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm8 12a8 8 0 11-16 0 8 8 0 0116 0zM1 12C1 5.935 5.935 1 12 1v2c-4.962 0-9 4.038-9 9a8.972 8.972 0 002.639 6.361l-1.414 1.414.015.014A10.97 10.97 0 011 12z"> </path>
        </StyledIcon>
    )
};

export const IconCheckMark = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode, stroke?: string }) => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } stroke={ props?.stroke } d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"> </path>
        </StyledIcon>
    )
};

export const IconCheckMarkNoCircle = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M9 22L-1 11.402l2.798-2.859 7.149 7.473L22.091 2 25 4.806z"></path>
        </StyledIcon>
    )
};

export const IconCloseAlt = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            <path
                fillRule="nonzero"
                d="M21 3.998c0-.478-.379-1-1-1H4c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-8.991 6.932l2.717-2.718a.75.75 0 111.062 1.062l-2.718 2.717 2.728 2.728a.75.75 0 11-1.061 1.062l-2.728-2.728-2.728 2.728a.75.75 0 01-1.061-1.062l2.728-2.728-2.722-2.722a.75.75 0 011.061-1.061z"
            > </path>
        </StyledIcon>
    )
};

export const IconClose = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
        >
            <path d="M12 10.93l5.719-5.72a.749.749 0 111.062 1.062l-5.72 5.719 5.719 5.719a.75.75 0 11-1.061 1.062L12 13.053l-5.719 5.719A.75.75 0 015.22 17.71l5.719-5.719-5.72-5.719A.752.752 0 016.281 5.21z"> </path>
        </StyledIcon>
    )
};

export const IconLifeRing = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm8.975 16.383l-2.608-1.485a6.956 6.956 0 000-5.795l2.608-1.485C21.625 8.943 22 10.427 22 12s-.375 3.057-1.025 4.383zM2 12c0-1.573.375-3.057 1.025-4.383l2.608 1.485a6.956 6.956 0 000 5.795l-2.608 1.485A9.92 9.92 0 012 12zm5 0c0-2.757 2.243-5 5-5s5 2.243 5 5-2.243 5-5 5-5-2.243-5-5zm9.397-8.968L14.912 5.64C14.024 5.233 13.04 5 12 5s-2.024.233-2.912.64L7.603 3.032C8.932 2.378 10.421 2 12 2s3.068.378 4.397 1.032zM7.603 20.968l1.485-2.608c.888.407 1.872.64 2.912.64s2.024-.233 2.912-.64l1.485 2.608C15.068 21.622 13.579 22 12 22s-3.068-.378-4.397-1.032z"> </path>
        </StyledIcon>
    )
};

export const IconGear = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M24 13.616v-3.232l-2.869-1.02a9.445 9.445 0 00-.811-1.955l1.308-2.751-2.285-2.285-2.751 1.307a9.468 9.468 0 00-1.955-.811L13.616 0h-3.232L9.363 2.869a9.413 9.413 0 00-1.955.811L4.657 2.372 2.372 4.657 3.68 7.409a9.39 9.39 0 00-.811 1.955L0 10.384v3.232l2.869 1.02a9.39 9.39 0 00.811 1.955l-1.308 2.751 2.285 2.286 2.751-1.308a9.468 9.468 0 001.955.811L10.384 24h3.232l1.021-2.869a9.445 9.445 0 001.955-.811l2.751 1.308 2.285-2.286-1.308-2.751a9.445 9.445 0 00.811-1.955L24 13.616zM12 16a4 4 0 110-8 4 4 0 010 8z"> </path>
        </StyledIcon>
    )
};

export const IconProfile = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22a9.983 9.983 0 01-7.749-3.69c.259-.588.783-.995 1.867-1.246 2.244-.518 4.459-.981 3.393-2.945C6.356 8.299 8.612 5 12 5c3.322 0 5.634 3.177 2.489 9.119-1.035 1.952 1.1 2.416 3.393 2.945 1.082.25 1.61.655 1.871 1.241A9.982 9.982 0 0112 22z"> </path>
        </StyledIcon>
    )
};

export const IconProfileThin = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79C14.709 5.615 13.508 5 12 5c-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848C5.884 21.615 8.782 23 12 23s6.115-1.385 8.127-3.59zm.65-.782A10.949 10.949 0 0023 12c0-6.071-4.929-11-11-11S1 5.929 1 12c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745C8.584 4.82 10.139 4 12 4c1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z"> </path>
        </StyledIcon>
    )
};

export const IconQuestionThin = () => {
    return (
        <StyledIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11S1 18.071 1 12 5.929 1 12 1zm.053 17a.844.844 0 100-1.689.844.844 0 000 1.689zm.468-2.822h-.998c-.035-1.162.182-2.054.939-2.943.491-.57 1.607-1.479 1.945-2.058C15.129 8.948 14.484 7 12.136 7c-1.439 0-2.615.877-2.928 2.507L8.19 9.405C8.47 7.169 10.148 6 12.112 6c1.964 0 3.615 1.25 3.615 3.22 0 1.806-1.826 2.782-2.638 3.868-.422.563-.555 1.377-.568 2.09z"> </path>
        </StyledIcon>
    )
};

export const IconQuestionFilled = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 18.25a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm1.961-5.928c-.904.975-.947 1.514-.935 2.178h-2.005c-.007-1.475.02-2.125 1.431-3.468.573-.544 1.025-.975.962-1.821-.058-.805-.73-1.226-1.365-1.226-.709 0-1.538.527-1.538 2.013h-2.01c0-2.4 1.409-3.95 3.59-3.95 1.036 0 1.942.339 2.55.955.57.578.865 1.372.854 2.298-.016 1.383-.857 2.291-1.534 3.021z"></path>
        </svg>
    )
};

export const IconReferAFriend = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="32"
            height="32"
            className={props?.className}
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill || "#662113"}
                d="M22.088 32.83c0 1.997-1.619 2.712-3.616 2.712s-3.616-.715-3.616-2.712l.904-8.136c0-1.996.715-1.808 2.712-1.808c1.997 0 2.712-.188 2.712 1.808l.904 8.136z"
            />
            <path
                fill="#5C913B"
                d="M30.59 27.675c-6.294-5.392-8.843-13.112-12.118-13.112s-5.824 7.721-12.118 13.112c-2.645 2.266-1.385 3.577 2.241 3.314c3.232-.233 6.255-.966 9.877-.966s6.646.733 9.876.966c3.627.263 4.886-1.049 2.242-3.314z"
            />
            <path
                fill="#3E721D"
                d="M28.546 25.538c-4.837-4.435-7.555-10.787-10.074-10.787c-2.517 0-5.236 6.352-10.073 10.787c-3.844 3.524 5.037 4.315 10.073 0c5.034 4.316 13.917 3.524 10.074 0z"
            />
            <path
                fill="#5C913B"
                d="M28.546 21.945c-4.837-4.143-7.555-10.077-10.074-10.077c-2.517 0-5.236 5.934-10.073 10.077c-3.844 3.292 5.037 4.031 10.073 0c5.034 4.032 13.917 3.292 10.074 0z"
            />
            <path
                fill="#3E721D"
                d="M26.336 19.003c-3.775-3.235-5.897-7.868-7.864-7.868c-1.965 0-4.088 4.632-7.864 7.868c-3 2.57 3.932 3.147 7.864 0c3.93 3.146 10.865 2.57 7.864 0z"
            />
            <path
                fill="#5C913B"
                d="M26.336 16.311c-3.775-3.235-5.897-7.867-7.864-7.867c-1.965 0-4.088 4.632-7.864 7.867c-3 2.571 3.932 3.147 7.864 0c3.93 3.147 10.865 2.571 7.864 0z"
            />
            <path
                fill="#3E721D"
                d="M24.818 13.92c-3.047-2.61-4.76-6.349-6.347-6.349c-1.586 0-3.299 3.739-6.347 6.349c-2.422 2.075 3.174 2.54 6.347 0c3.174 2.54 8.77 2.075 6.347 0z"
            />
            <path
                fill="#5C913B"
                d="M24.818 11.894c-3.047-2.61-4.76-6.349-6.347-6.349c-1.586 0-3.299 3.739-6.347 6.349c-2.422 2.075 3.174 2.54 6.347 0c3.174 2.54 8.77 2.075 6.347 0z"
            />
            <path
                fill="#3E721D"
                d="M23.301 8.911C20.983 6.925 19.68 4.08 18.472 4.08c-1.207 0-2.511 2.845-4.83 4.831c-1.843 1.579 2.415 1.933 4.83 0c2.414 1.933 6.673 1.579 4.829 0z"
            />
            <path
                fill="#5C913B"
                d="M23.301 7.832C20.983 5.845 19.68 3 18.472 3c-1.207 0-2.511 2.845-4.83 4.832c-1.843 1.579 2.415 1.933 4.83 0c2.414 1.932 6.673 1.578 4.829 0z"
            />
            <circle fill="#FFCC4D" cx="16.5" cy="12.042" r="1.5" />
            <circle fill="#FFCC4D" cx="20" cy="25" r="2" />
            <circle fill="#DD2E44" cx="22.5" cy="15.5" r="2.5" />
            <circle fill="#DD2E44" cx="10" cy="26" r="2" />
            <circle fill="#A6D388" cx="14" cy="19" r="2" />
            <circle fill="#A6D388" cx="26" cy="23" r="2" />
            <path
                fill="#FFAC33"
                d="M19.379 1.679c.143.275.512.549.817.609l1.379.269c.305.06.377.29.159.512l-.983 1.004c-.217.222-.365.657-.326.965l.17 1.394c.038.309-.159.448-.438.31L18.9 6.117c-.279-.138-.738-.144-1.02-.013l-1.274.594c-.282.13-.476-.014-.43-.322l.205-1.39c.045-.307-.091-.745-.302-.973l-.959-1.027c-.212-.227-.135-.457.172-.508l1.385-.234c.307-.051.681-.316.832-.588L18.19.427c.151-.272.394-.269.537.006l.652 1.246z"
            />
        </svg>
    );
};

export const IconReferAFriendTree = (props?: { iconFill?: string; className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 506.603 506.603" // Original viewBox
            width="32"
            height="32"
            className={props?.className}
        >
            <path
                fill={props?.iconFill || "#3B322E"} // Default fill color
                d="M253.261,1120.814c-2.406,0.012-4.678,1.106-6.188,2.979l-85.333,106.271
                c-2.772,3.44-2.231,8.478,1.21,11.25c1.422,1.146,3.193,1.771,5.019,1.771h25.249l-63.312,73.729
                c-2.876,3.354-2.488,8.405,0.867,11.281c1.447,1.241,3.29,1.924,5.196,1.927h46.25l-73.583,83.313
                c-2.934,3.305-2.633,8.361,0.671,11.294c1.468,1.305,3.365,2.022,5.329,2.018h277.333c4.419,0.009,8.008-3.565,8.018-7.984
                c0.004-1.964-0.714-3.859-2.018-5.328l-73.584-83.313h46.251c4.419-0.006,7.995-3.594,7.989-8.013
                c-0.003-1.906-0.686-3.749-1.927-5.195l-63.312-73.729h25.25c4.419,0.01,8.01-3.563,8.02-7.98c0.005-1.833-0.621-3.613-1.771-5.04
                l-85.333-106.271C258.019,1121.893,255.702,1120.796,253.261,1120.814L253.261,1120.814z"
            />
            <path
                fill={props?.iconFill || "#5DA771"}
                d="M253.367,1141.585l68.646,85.5h-26c-4.419,0.007-7.996,3.594-7.99,8.012
                c0.003,1.906,0.686,3.749,1.927,5.197l63.312,73.729H306.68c-4.419-0.009-8.008,3.565-8.018,7.984
                c-0.004,1.964,0.714,3.859,2.018,5.328l73.583,83.313H132.43l73.584-83.313c2.934-3.305,2.633-8.361-0.671-11.294
                c-1.468-1.305-3.365-2.022-5.329-2.018H153.43l63.312-73.729c2.876-3.354,2.488-8.405-0.867-11.281
                c-1.447-1.241-3.289-1.924-5.195-1.927h-25.98L253.367,1141.585z"
            />
        </svg>
    );
};

export const IconAccepted = (props?: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="32"
            height="32"
            className={props?.className}
        >
            <circle cx="18" cy="18" r="16" fill="#006400" /> {/* Dark green */}
            <path
                fill="#FFF" // White checkmark
                d="M13 18.5l3.5 3.5 7-7 1.5 1.5-8.5 8.5L11.5 20l1.5-1.5z"
            />
        </svg>
    );
};

export const IconRefused = (props?: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="32"
            height="32"
            className={props?.className}
        >
            <circle cx="18" cy="18" r="16" fill="#DD2E44" /> {/* Red */}
            <path
                fill="#FFF" // White cross
                d="M12 12l12 12m-12 0L24 12"
                stroke="#FFF"
                strokeWidth="2"
            />
        </svg>
    );
};

export const IconEasterEggs = (props?: { iconFill?: string; className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 492.441 492.441" // Original viewBox
            width="32"
            height="32"
            className={props?.className}
        >
            <path
                fill={props?.iconFill || "#000000"} // Default black fill color
                d="M76.416,324.566l-27.919,41.685c23.757,19.579,53.675,30.287,83.303,24.426c4.386-0.872,8.597-2.17,12.708-3.686
                c-11.177-9.499-20.24-21.369-27.127-34.981L76.416,324.566z"
            />
            <path
                fill={props?.iconFill || "#000000"}
                d="M88.607,172.301l32.644-48.754l45.772,30.642c-27.45-37.328-62.48-66.609-93.964-60.383
                c-31.464,6.216-52.723,46.634-63.882,91.608l30.658-45.764L88.607,172.301z"
            />
            <path
                fill={props?.iconFill || "#000000"}
                d="M107.43,325.469c-1.597-6.185-2.968-12.515-3.79-19.151c-1.404-11.339-1.419-22.775-0.372-34.048l-42.883-28.708
                l-32.66,48.756L0.612,274.15c0.468,6.322,1.113,12.354,2.162,17.66c3.435,17.306,11.161,34.031,21.741,48.756l14.708,9.854
                l32.659-48.765L107.43,325.469z"
            />
            <path
                fill={props?.iconFill || "#000000"}
                d="M55.867,220.646l48.757,32.659l2.306-3.435c3.902-16.258,10.273-31.693,19.062-45.224
                c7.339-11.298,18.354-24.048,31.741-36.804l-31.95-21.386l-32.659,48.755l-48.756-32.659l-32.659,48.763l-7.08-4.75
                C1.807,222.789,0.274,238.982,0,253.878l23.209,15.531L55.867,220.646z"
            />
            <path
                fill={props?.iconFill || "#000000"}
                d="M391.963,150.826c17.967,0,34.546-1.662,49.434-4.459c-14.597-18.007-31.45-30.015-49.434-30.015
                c-8.982,0-17.693,3.007-25.982,8.258c4.661,7.588,8.16,16.193,10.676,25.514C381.722,150.406,386.656,150.826,391.963,150.826z"
            />
            <path
                fill={props?.iconFill || "#000000"}
                d="M492.344,283.811c-26.063,12.096-61.157,19.467-100.381,19.467c-10.468,0-20.564-0.598-30.338-1.597
                c-5.871,16.16-12.386,30.191-18.87,40.667c14.838,2.79,31.305,4.484,49.208,4.484c37.53,0,69.124-7.057,91.236-17.192
                c5.871-14.218,9.242-29.377,9.242-44.328C492.441,284.843,492.36,284.287,492.344,283.811z"
            />
            {/* Additional paths removed for brevity */}
        </svg>
    );
};